$(document).ready(function() {
    const $slider = $('.heroSlider');

    $slider.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        dots: true,
        appendDots: $('.dotsWrap')
    });

    const handleVideoPlayback = ($currentSlide) => {
        const $video = $currentSlide.find('video');
        if ($video.length > 0) {
            const video = $video.get(0);

            video.muted = true;
            video.play();

            $video.off('ended').on('ended', function() {
                $slider.slick('slickNext');
            });

            $slider.slick('slickSetOption', 'autoplay', false, true);
        } else {
            $slider.slick('slickSetOption', 'autoplay', true, true);
        }
    };

    $slider.on('init', function(event, slick) {
        const $currentSlide = $(slick.$slides[slick.currentSlide]);
        handleVideoPlayback($currentSlide);
    });

    $slider.on('afterChange', function(event, slick, currentSlide) {
        const $currentSlide = $(slick.$slides[currentSlide]);
        handleVideoPlayback($currentSlide);
    });

   $slider.slick('slickGoTo', 0);
});
