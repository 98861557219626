const langItem = document.querySelectorAll('.langNav li a');

/* responsive menu */
$('.hamburger').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('body').toggleClass('active');
    $('.mobileMenu').slideToggle();
});

// remove 'active' class from body when a menu link is clicked
$('.mobileMenu a').click(function (e) {
    $('.hamburger').removeClass('active');
    $('body').removeClass('active');
    $('.mobileMenu').slideUp();
});

/* clear content from modal on close */
$('#contactModal').on('hidden.bs.modal', function (e) {
    $(this)
        .find("input,textarea")
        .val('')
        .end()
})

/* active language */
langItem.forEach(lang => {
    lang.addEventListener('click', (e) => {
        e.preventDefault();
        langItem.forEach(item => item.classList.remove('active'));
        e.currentTarget.classList.add('active');
    })
})

$(function () {
    try {
        $(".swipebox").swipebox();
    } catch (e) {
        console.error("swipebox not loaded");
    }
})




